import uuid from 'uuid/v4'
import isEmpty from 'lodash/isEmpty'

export default function useNestedAttributes(model, setModel, attributesKey) {
  const attributes = model[attributesKey]
  const setAttributes = attrs => setModel({ ...model, [attributesKey]: attrs })
  const add = () => {
    setAttributes({ ...attributes, [uuid()]: {} })
  }
  const remove = key => {
    const newAttributes = { ...attributes }
    if (newAttributes[key].id) {
      newAttributes[key]._destroy = true
    } else {
      delete newAttributes[key]
    }
    setAttributes(newAttributes)
  }
  const set = (key, data) => {
    setAttributes({ ...attributes, [key]: data })
  }

  const allAttributesAreDestroyed = () => Object.values(attributes).every(attr => attr._destroy === true)
  const noVisibleAttributes = isEmpty(attributes) ? true : allAttributesAreDestroyed()

  return [attributes, noVisibleAttributes, add, remove, set]
}
