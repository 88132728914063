import React from 'react'
import RemoveFieldsButton from '../shared/RemoveFieldsButton'

export default function NestedFieldset(props) {
  const { children, className, onAddButtonClick, title, addButtonTitle } = props
  return (
    <fieldset className={`nested-fieldset border rounded-lg ${className}`}>
      <div className="nested-fieldset-header sticky-top p-2 d-flex justify-content-between bg-light border-bottom">
        <div className="nested-fieldset-header-title">{title}</div>
        <button className="btn btn-outline-primary btn-sm" type="button" onClick={onAddButtonClick}>
          {addButtonTitle}
        </button>
      </div>
      <div className="nested-fieldset-content p-2">{children}</div>
    </fieldset>
  )
}
