import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import Popup from 'reactjs-popup'
import useAxios from 'axios-hooks'
import { update_job_plannings_path } from '../../../routes'

function PlacementField(props) {
  const defaultPlacement = props.placement === null ? '' : props.placement
  const { jobPlanningKey } = props
  const [placement, setPlacement] = useState(defaultPlacement)
  const [{ data, loading }, executeUpdate] = useAxios(
    {
      url: update_job_plannings_path({format: 'json'}),
      method: 'POST',
    },
    { manual: true, useCache: false }
  )

  async function updateJobPlanning(payload) {
    await executeUpdate({ data: { job_planning: { ...payload } } })
    props.reload()
  }

  let trigger = null
  if (loading) {
    trigger = <i className="fa fa-spinner fa-spin" />
  } else if (props.placement) {
    trigger = <a className="text-underline">{props.placement}</a>
  } else {
    trigger = (
      <button className="btn btn-outline-secondary" type="button">
        <i className="fa fa-edit" />
      </button>
    )
  }
  return (
    <div className="d-inline job-plannings-placement-field">
      <Popup
        trigger={trigger}
        position="bottom center"
        contentStyle={{ width: 'auto', maxWidth: '100%' }}
        closeOnDocumentClick
        modal
        nested
      >
        {close => (
          <div className="popup p-3">
            <textarea
              autoFocus
              className="input-field form-control"
              value={placement}
              maxLength={30}
              onChange={e => {
                setPlacement(e.target.value)
              }}
            />
            <div className="text-right">
              <small>max 30 tegn</small>
            </div>
            <div className="actions mt-3 d-flex justify-content-between">
              <button className="btn btn-outline-secondary" onClick={close}>
                Annuller
              </button>
              <button
                className="btn btn-primary"
                onClick={() => {
                  close()
                  updateJobPlanning({ placement, key: jobPlanningKey })
                }}
              >
                Gem
              </button>
            </div>
          </div>
        )}
      </Popup>
    </div>
  )
}

PlacementField.propTypes = {
  jobPlanningKey: PropTypes.string.isRequired,
  reload: PropTypes.func.isRequired,
  placement: PropTypes.string,
}

export default PlacementField
