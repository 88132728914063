import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'

function PartnersPicker({ partnerIds, setPartnerIds, name, availablePartners, allPartners }) {
  const [showAllUsers, setShowAllUsers] = useState(false)
  const options = (showAllUsers ? allPartners : availablePartners).map(card => {
    return { value: card.id, label: card.name }
  })
  const selected = options.filter(option => partnerIds.includes(option.value))
  return (
    <div className="row">
      <div className="col-sm-12 form-group">
        <label>Partners</label>
        <Select
          id="record-partners-select"
          value={selected}
          onChange={opts => setPartnerIds(opts.map(o => o.value))}
          isMulti
          name={name}
          options={options}
        />
        {allPartners.length > 0 && (
          <label>
            <input type="checkbox" checked={showAllUsers} onChange={e => setShowAllUsers(e.target.checked)} />
            <span className="record-partners-select-show-all">Vis alle</span>
          </label>
        )}
      </div>
    </div>
  )
}

PartnersPicker.propTypes = {
  partnerIds: PropTypes.array.isRequired,
  setPartnerIds: PropTypes.func.isRequired,
  availablePartners: PropTypes.array.isRequired,
  allPartners: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
}

export default PartnersPicker
